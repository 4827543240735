.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*----------------------
INPUT ANIMATION
------------------------*/

.input-animate.input,
.login-animate.input label,
.login-animate.input input,
.login-animate.input textarea,
.login-animate.input .spin {  transition: 300ms cubic-bezier(.4, 0, .2, 1); -webkit-transition: 300ms cubic-bezier(.4, 0, .2, 1); -ms-transition: 300ms cubic-bezier(.4, 0, .2, 1);}

.input-animate.input input,
.input-animate.input textarea,  
.input-animate.input label { font-size: 12px;  color: rgba(0, 0, 0, 0.8); font-weight: 500; }

.input-animate.input  { margin-bottom: 30px;  height: 44px; }
.input-animate.input::before { content: ""; background: rgba(255, 255, 255, 0.1); z-index: 3; }

.input-animate.input input{ height: 44px; top: 10px; border: none; background: transparent; }
.input-animate.input textarea{ height: 40px; padding-top:15px; top: 10px; border: none; background: transparent; }

.input-animate.input label { position: absolute; top: 10px; left: 0; z-index: 2; cursor: pointer; line-height: 40px; }
.input-animate.input input, 
.input-animate.input textarea, 
.input-animate.input label { font-size: 12px; color:#fff; font-weight: 500; }

.input-animate.input::before, 
.input-animate.input .spin { width: 100%; height: 2px; position: absolute; bottom: 0; left: 0; }

.input-animate.input, 
.input-animate.input input,
.input-animate.input textarea{ position: relative; width:100%;outline: none;}

.input-animate.input textarea{resize:none;}

.input-animate.input .spin { background: #f5bf23; z-index: 4; width: 0; }

.input-animate.input, 
.input-animate.input label, 
.input-animate.input input,
.input-animate.input textarea,  
.input-animate.input .spin { transition: 300ms cubic-bezier(.4, 0, .2, 1); -webkit-transition: 300ms cubic-bezier(.4, 0, .2, 1); -ms-transition: 300ms cubic-bezier(.4, 0, .2, 1); box-shadow: none;}


/*----------------------
CUSTOM STYLES
------------------------*/
.mt-tilte {
  margin-inline: 0px;
  word-spacing: 0px;
}

.gmap-outline {
  position: relative;
}

.mapBox {
  border-radius: 0px 5px 0px 0px;
  position: absolute;
  bottom:-6%;
  left: 0%;
  z-index: 3;
  padding: 10px;
  background: white;
  font-size: 18px;
  font-weight: 400;
  font-family: Roboto, Arial, sans-serif;
}